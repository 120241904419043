import { useEffect, useState } from 'react';

export default function ChatwootWidget({customAttributes}) {
  const [chatReady, setChatReady] = useState(false)
  useEffect(() => {
    // Création du script
    
    console.log(`${process.env.REACT_APP_BASE_CHAT_URL}/packs/js/sdk.js`)
    const script = document.createElement('script');
    script.src =   `${process.env.REACT_APP_BASE_CHAT_URL}/packs/js/sdk.js`;
    script.type = "text/babel";
    script.async = true;
    script.defer = true;
    // Fonction à exécuter une fois le script chargé
    script.onload = () => {
      try{
        window.chatwootSDK.run({
          websiteToken: process.env.REACT_APP_CHAT_TOKEN,
          baseUrl: process.env.REACT_APP_BASE_CHAT_URL,
        });
        setTimeout(function() {
          var currIcons = document.querySelectorAll('#woot-widget-bubble-icon');
          for (var j = 0; j < currIcons.length; j++) {
              try{
                currIcons[j].remove()
              }catch(e){
                console.log(e)
              }
          }
          let currImg = document.querySelectorAll('#chatwoot-img');
          if(currImg.length === 0){
            var bubbleElements = document.querySelectorAll('.woot-widget-bubble');
            var img = document.createElement('img');
            img.id = "chatwoot-img"
            img.src = "/logo192.png"; // REPLACE WITH THE IMAGE YOU WANT
            img.width = 46; // CHANGE THE WIDTH OF THE IMAGE IF IT DOESN'T LOOK HOW YOU EXPECT
            bubbleElements[0].appendChild(img);
          }
        }, 2000);
      }
      catch(e){
        console.log(e)
      }
    };

    // Ajout du script au document
    document.body.appendChild(script);

    // Nettoyage : suppression du script lorsque le composant est démonté
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(()=>{
    // if(window.$chatwoot !== undefined && chatReady){
    //     window.$chatwoot.setCustomAttributes(customAttributes)
    // }
    var i,elements = document.getElementsByClassName("branding--link")
    for (i = elements.length; i--;) {         
        elements[i].parentNode.removeChild(elements[i]);             
      }
  },[customAttributes, chatReady])

  window.addEventListener("chatwoot:ready", function () {
    setChatReady(true)
  });
  
  

  return null; // Ce composant n'affiche rien
}